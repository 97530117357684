<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Your new bitcoin wallet has been confirmed.': 'Sua nova carteira de bitcoin foi confirmada.',
      'The token is invalid.': 'O token é inválido.',
      'Sign In here': 'Entrar',
    },
    es: {
      'Your new bitcoin wallet has been confirmed.': 'Su nueva cartera bitcoin ha sido confirmada.',
      'The token is invalid.': 'El token no es válido.',
      'Sign In here': 'Identifícate aquí',
    }
  },
  components: { Layout },
  data() {
    return {
      success: false,
      token: null,
    };
  },
  methods: {
    getBitcoin() {
      api
        .get('bitcoin/confirm/'+this.token)
        .then(response => {
          if (response.data.status=='success') {
            this.success = true
          } else {
            this.success = false
          }
        })
    }
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token
      this.getBitcoin();
    }
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="row m-0 flex-column-reverse flex-sm-row align-items-center">
      <div class="col">
        <div class="login-content">
          <div class="pt-5 text-center">
            <router-link class="align-middle" tag="a" to="/">
              <img class="w-100" style="max-width:250px;" src="@/assets/images/logo-binarytrade-full.png" />
            </router-link>
          </div>
          <div class="pt-5">
            <div v-if="success" class="p-4 text-center">
              {{ t('Your new bitcoin wallet has been confirmed.') }}
            </div>
            <div v-else class="p-4 text-center">
              {{ t('The token is invalid.') }}
            </div>
          </div>
        </div>
        <div class="mt-4 text-center">
          <p class="text-white">
            <router-link tag="a" to="/" class="font-weight-medium text-success">{{ t('Sign In here') }}</router-link>
          </p>
        </div>
      </div>
      <div class="col login-image d-none d-lg-block"></div>
    </div>
  </Layout>
</template>